<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 15:12:38
-->
<template>
	<div>
		<div class="pad-row">
			<div class="whiteBg pad-row tt">
				<p><span>项目编号</span><span>{{project.projectNum}}</span></p>
				<p><span>项目名称</span><span>{{project.projectName}}</span></p>
				<p><span>采购单位</span><span>{{project.purchaseOrg}}</span></p>
				<p><span>评审时间</span><span>{{project.reviewDatetime}}</span></p>
				<p><span>评审地点</span><span>{{project.reviewSite}}</span></p>
			</div>
			<div class="whiteBg pad-row mar-t2 tt">
				<p><span>包组编号</span><span>{{project.packageNum}}</span></p>
				<p><span>专家人数</span><span>{{project.expertNum}}</span></p>
				<p>
					<span>评审专业</span>
					<span>
						<template v-for="(majorItem) in majorList">
							<p>{{majorItem.parentMajorName}}-{{majorItem.majorName}}</p>
						</template>
					</span>
				</p>
			</div>

			<div class="whiteBg pad-row mar-t2">
				<div class="flex-between">
					<h3>抽取结果</h3>
					<button @click="sendSms" class="msgBtn fr" :class="{disabled : !showBtn}" :disabled="!showBtn" v-show="vshowBtn">
						{{ btnText }}
					</button>
				</div>
				<div class="flex-between mar-t2" v-for="(expertRecord) in currentViewProject.inExpertRecords" :key="expertRecord.id">
					<div class="tempW">
						<h3 class="fc inline mar-r2 f45">{{expertRecord.expertName}}</h3>
						<h3 class="inline f45">{{expertRecord.expertPhone}}</h3>
						<div v-if="expertRecord.majorName">
							<p class="">{{ expertRecord.majorParentName }}-{{ expertRecord.majorName }}</p>
						</div>
					</div>

					<div class="flex-item">
						<span class="blue fw">参与</span>
						<van-icon name="arrow" color="#C7C7CC" size="0.5rem" />
					</div>
				</div>
			</div>
			<p class=" f35 mar-t2 gray2">请在电脑端项目记录中打印</p>
			<div class="h2"></div>
		</div>
		<div class="fixed-bottom whiteBg w-100 pad25">
			<button @click="finish()">完成</button>
		</div>
	</div>
</template>

<script>
	import {
		Icon,
		Dialog
	} from 'vant'
	export default {
		data() {
			return {
				project: {},
				majorList: [],
				currentViewProject: {
					extractRoundVoList: [],
					inExpertRecords: [],
					allExpertRecords: [],
				},
				btnText: '短信一键通知',
				showBtn: true,
				timer: 120,
				timeInterval: '',
				vshowBtn: false,
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},
		beforeMount() {
			this.$emit('onActive', 3)
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			this.project = this.$ls.get('temp-project');
			this.getProjectInfo();
			/** 若用户处于试用期或已开通服务或者开通服务后服务到期都可显示短信服务菜单 */
			if (this.$ls.get('edCurrentOrgExternal') &&
				this.$ls.get('edCurrentOrgExternal').serviceStatus &&
				this.$ls.get('edCurrentOrgExternal').serviceStatus != '00') {
				this.vshowBtn = true;
			} else {
				this.vshowBtn = false;
			}
		},

		watch: {
			timer(newV, oldV) {
				if (newV == 0) {
					clearInterval(this.timeInterval)
					this.btnText = '短信一键通知'
					this.showBtn = true
					this.timer = 120
				}
			}
		},

		components: {
			[Icon.name]: Icon
		},

		beforeRouteLeave(to, from, next) {
			if (to.name == 'home')
				next()
			else
				next(false)
		},

		methods: {
			finish: function() {
				this.$router.push({
					name: 'home'
				})
			},


			/**
			 * @Description: 根据项目ID获取抽取信息
			 * @Author: Niklaus
			 * @Date: 2021-03-26 14:42:46
			 */
			getProjectInfo() {
				let params = new URLSearchParams()
				params.append("projectId", this.project.id)
				params.append("openid", this.workweixin?this.openUserid:this.$ls.get("openid"))
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/wxEdProjectController/selectByProjectId/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.currentViewProject = res.data.result;
						this.majorList = this.currentViewProject.majorList;

					} else {
						this.$toast.fail("失败")
						console.log(res.data.message)
					}
				});
			},

			/**
			 * @Description: 发送短信
			 * @Author: Niklaus
			 * @Date: 2021-03-26 16:02:12
			 */
			sendSms: function() {
				let _this = this

				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				this.$ajax({
					url: "/gateway/ed/sms/sendSms",
					method: "get",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
						projectId: this.project.id,
					},
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						_this.showBtn = false
						_this.btnText = "倒计时" + _this.timer + "s"
						this.timeInterval = setInterval(() => {
							_this.showBtn = false
							_this.timer--;
							_this.btnText = "倒计时" + _this.timer + "s"
						}, 1000);
					} else if (res.status == "200" && (res.data.retCode == "2" || res.data.retCode == "3")) {
						this.btnText = '短信一键通知'
						this.showBtn = true
						this.timer = 120
						Dialog.confirm({
							title: '开通短信服务',
							message: res.data.errMsg,
							confirmButtonText: '去开通服务',
							cancelButtonText: '取消',
						}).then(() => { //确认
							this.$router.push({
								path: '/smsrecharge'
							});
						}).catch(() => {
							console.log('取消')
						})
					} else {
						this.btnText = '短信一键通知'
						this.showBtn = true
						this.timer = 120
						this.$toast({
							message: res.data.errMsg,
							type: 'fail',
							duration: 5000
						})
					}
				}).catch((err) => {
					this.$toast({
						message: "请求错误",
						type: 'fail',
						duration: 5000
					})
					this.btnText = '短信一键通知'
					this.showBtn = true
					this.timer = 120
					console.log(err)
				})
			},
		}
	}
</script>

<style scoped>
	.tt>p {
		display: table-row;
		line-height: 0.8rem;
	}

	.tt>p>span {
		display: table-cell;
		font-weight: 500;
		padding: 0.2rem 0;
	}

	.tt>p>span:first-child {
		width: 2rem;
	}

	.tt>p>span:last-child {
		color: #737373;
	}

	.c4 {
		width: calc(100% - 3.5rem);
	}

	.flex-item {
		padding-top: 0.2rem;
	}

	.fixed-bottom>button {
		width: 100%;
		padding: 0.15rem 0.1rem;
		text-align: center;
		background-color: #00CCB2;
		font-weight: bold;
		color: white;
		border-radius: 0.5rem;
	}

	.w15 {
		width: 1.7rem;
	}
</style>
